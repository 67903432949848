
import logo from '../../../assets/img/logo-agroskills.png'

import {Link, useLocation, useNavigate} from "react-router-dom";
import menus from "../../../router/menus";
import {useState} from "react";
import Storage from "../../../helpers/Storage";
import Dropdown from "../../dropdown/Dropdown";
import { FaAngleDown } from "react-icons/fa";
import authApi from "../../../services/authApi";
export default function Navbar() {
    const navigate = useNavigate();
    const userData: any = Storage.getUserData();
    const { pathname: currentPath } = useLocation();
    const validatePage = currentPath.split('/')[1];
    const [openMenu, setOpenMenu] = useState(false);
    async function logoutUser() {
        await authApi.logout();
        Storage.removeApiToken();
        Storage.removeUserData();
        window.location.href = '/';
    }
    return(
        <nav className="bg-primary">
            <div className="mx-auto  px-2 sm:px-6 lg:px-8">
                <div className="relative flex p-4 items-center justify-between">

                    <div className="flex flex-1 items-stretch justify-start">
                        <div className="flex  flex-shrink-0  gap-3">
                            <img className="w-[200px] h-[40px]"
                                 src={logo}
                                 alt="Your Company"/>

                        </div>
                    </div>
                    <div className="absolute gap-3 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">


                        <div className="relative ml-3">
                            <div className={'flex items-center h-full '}>
                                <Dropdown
                                    trigger={
                                        <div className={`flex h-[40px] justify-center items-center py-2  rounded-md font-semibold text-[#F5F5F5] text-[20px]`}>
                                            {userData?.usuario?.nome} <FaAngleDown />
                                        </div>
                                    }
                                    items={[
                                        { label: 'Meu perfil', onClick: () => navigate(`/meu-perfil`) },
                                        { label: 'Sair', onClick: () => logoutUser()}
                                    ]}
                                />
                            </div>
                        </div>

                        {/*Mobile menu button*/}
                        <div className="flex items-center sm:hidden">
                            <button type="button" onClick={() => setOpenMenu(!openMenu)} className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                <span className="absolute -inset-0.5"></span>
                                <span className="sr-only">Open main menu</span>
                                <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                                </svg>
                                <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${openMenu ? '' : 'sm:hidden max-[640px]:hidden'}`} id="mobile-menu">
                <div className="space-y-1 px-2 pb-3 pt-2">
                    {
                        menus.map((menu) => {


                            return(
                                <Link to={menu.url ? menu.url : menu.path} target={menu.url ? '_blank' : '_self'} className={`flex items-center p-3 ${'/'+validatePage === menu.path ? 'text-system-primary border-l-4 border-system-primary' : 'text-[#B5B5BE]'}   group hover:text-system-primary`} aria-current="page">
                                    {/*<menu.icon width={20} height={20} />*/}
                                    <span className="ms-3">{menu.title}</span>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </nav>

    )
}