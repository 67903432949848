import menus from "../../../router/menus";
import {createElement} from "react";
import {Link, useLocation} from "react-router-dom";
import communityApi from "../../../services/communityApi";
import Alert from "../../../helpers/Alert";
export default function SideBar() {
    const { pathname: currentPath } = useLocation();
    const validatePage = currentPath.split('/')[1];

    const goToCommunity = async () => {
        const {data, isError} = await communityApi.goToCommunity();
        if (!isError){
            window.open(data.url, "_blank")
            return ;
        }
        return Alert.error("Não foi possivel acessar o portal nesse momento")
    }

    return(
        <aside id="default-sidebar" className="w-80 h-full md:block xs:hidden" aria-label="Sidebar">
            <div className="h-full overflow-y-auto bg-white ">
                <ul className=" ">
                    {
                        menus.map((menu, index) => {
                            if (menu.comunidade){
                                return(
                                    <button onClick={() => goToCommunity()} /*to={menu.url ? menu.url : menu.path} target={menu.url ? '_blank' : '_self'}*/ className={`w-full flex items-center px-2 group ${!index ? 'mt-5' : '' }`}>
                                        {/*{menu.icon ? <menu.icon width={20} height={20} /> : ''}*/}
                                        <div className={`flex items-center gap-2 text-[#263465] rounded-md w-full p-2 hover:bg-[#EDEFF8] ${'/'+validatePage === menu.path ? 'bg-[#EDEFF8] font-bold ' : 'text-[#263465]'}`}>
                                            {menu.icon ? <menu.icon className={'w-[20px] h-[20px]'} /> : ''}<span className="">{menu.title}</span>
                                        </div>
                                    </button>
                                )
                            }
                            return(
                                <li>
                                    <Link to={menu.url ? menu.url : menu.path} target={menu.url ? '_blank' : '_self'} className={`flex items-center px-2 group ${!index ? 'mt-5' : '' }`}>
                                        {/*{menu.icon ? <menu.icon width={20} height={20} /> : ''}*/}
                                        <div className={`flex items-center gap-2 text-[#263465] rounded-md w-full p-2 hover:bg-[#EDEFF8] ${'/'+validatePage === menu.path ? 'bg-[#EDEFF8] font-bold ' : 'text-[#263465]'}`}>
                                            {menu.icon ? <menu.icon className={'w-[20px] h-[20px]'} /> : ''}<span className="">{menu.title}</span>
                                        </div>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </aside>
    )
}