import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';
import Storage from "../helpers/Storage";

const endpoint = 'https://nichoos.top/integration/sso';

export default {
    async goToCommunity(){
        try {
            const userData: any = Storage.getUserData();

            const url = endpoint+'?email='+userData.usuario.email+'&companyparam=agroskills&name='+userData.usuario.nome+'&phone='+userData.usuario.phone

            //https://nichoos.top/integration/sso?email=felipemoreiradecastro@gmail.com&companyparam=agroskills&name=Felipe Moreira de Castro&phone=62991252316


            const {data} = await api.get(url);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },

}