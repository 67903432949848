import { useEffect, useState } from "react";
import PageContainer from "../../../components/container/PageContainer";
import { EntrevistaResposta } from "../../../types/entrevista";
import service from "../../../services/entrevistaApi";
import Alert from "../../../helpers/Alert";
import Button from "../../../components/button/Button";

export default function Entrevistas() {
  const [contagens, setContagens] = useState([]);
  const [respostas, setRespostas] = useState<EntrevistaResposta[] | null>(null);
  const [data, setData] = useState({} as { qtde_obrigatorias: number });

  async function load() {
    Alert.await('Carregando respostas anteriores', 'Aguarde...');
    const { data, isError } = await service.listarRespostas();
    Alert.close();
    if (isError) {
      Alert.error(data.message || data.error || 'Falha ao carregar respostas.');
      return;
    }

    const count = [] as any;

    data.forEach(resposta => {
      if (count.includes(resposta.contagem_resposta)) return;
      count.push(resposta.contagem_resposta);
    });

    setContagens(count);
    setRespostas(data);
  }

  async function loadData() {
    const { data, isError } = await service.getData();
    if (isError) return;
    setData(data);
  }

  function getRespostasFromContagem(contagem: number) {
    return respostas?.filter(resposta => resposta.contagem_resposta === contagem);
  }

  async function novaEntrevista() {
    window.location.href = `/entrevista-simulada/responder`;
  }

  function isCompleted(contagem: number) {
    const respostasObrigatorias = getRespostasFromContagem(contagem)?.filter(resposta => resposta.pergunta?.obrigatorio);
    return (respostasObrigatorias ?? []).length >= data.qtde_obrigatorias;
  }

  useEffect(() => {
    load();
    loadData();
  }, []);

  return <PageContainer>
    <div className="flex gap-4 mb-12">
      <span className={'text-[30px]'}>Entrevistas Simuladas</span>
      <Button className='bg-primary h-[40px] text-white font-medium mt-1' onClick={novaEntrevista}>Responder</Button>
    </div>

    <div className="mb-10">
      <h4 className="font-bold text-lg mb-2">Orientações</h4>
      <div className="max-w-[540px]">
        <video controls src={service.getVideoUrl('entrevistas/entrevista-orientacao.mp4')}></video>
      </div>
    </div>


    <h4 className="font-bold text-lg mb-2">Minhas Respostas</h4>
    {respostas === null ? <></> : respostas?.length ? <div>
      <ul className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
        {contagens?.map(contagem => (
          <li 
            key={contagem} 
            className="bg-white rounded shadow transition h-full w-full p-4 hover:bg-slate-50 cursor-pointer relative"
            onClick={() => window.location.href =  isCompleted(contagem) ? `/entrevista-simulada/respostas/${contagem}` : `/entrevista-simulada/responder/${contagem}`}
          >
              <p className=" px-4 text-lg font-medium"><b>Empresa:</b> {getRespostasFromContagem(contagem)![0].resposta}</p>
              <p className=" px-4 text-lg font-medium"><b>Vaga:</b> {getRespostasFromContagem(contagem)![1]?.resposta}</p>
              <p className=" px-4 text-lg font-medium"><b>Data:</b> {getRespostasFromContagem(contagem)![0]?.created_at.split('T')[0].split('-').reverse().join('/')}</p>

              {isCompleted(contagem) ? <div className="absolute bg-green-400 top-0 right-0 p-1 rounded text-sm">Concluído</div>
              :<div className="absolute bg-blue-400 top-0 right-0 p-1 rounded text-sm">Em andamento</div>}
          </li>
        ))}
      </ul>
    </div> : (
      <div>
        <h1 className="text-lg">Você ainda não respondeu nenhuma entrevista</h1>
      </div>
    )}
  </PageContainer>
}