import { useState } from 'react';
import PageContainer from '../../../components/container/PageContainer';
import Alert from '../../../helpers/Alert';
import service from '../../../services/userApi';
import Camera from '../../../components/devices/Camera';
import useWebcam from '../../../hooks/useWebcam';

export default function GravarPitch() {
  const [isTesting, setIsTesting] = useState(true);
  const [video, setVideo] = useState<undefined | { blob: Blob, url: string }>();

  const webcam = useWebcam({
    videoTagId: 'video'
  });

  async function saveVideo() {
    try {
      Alert.await('Salvando vídeo pitch...');
      await service.savePitchVideo({ video: video!.blob });
      await Alert.success('Vídeo salvo com sucesso');
      window.location.href = '/meu-video';
    } catch (err: any) {
      return Alert.error(err.message || err.error || 'Falha ao salvar vídeo.');
    }
  }

  return (
    <PageContainer>
      <div className={'text-[30px] mb-4'}>Gravar Pitch</div>

    {isTesting ? (
      <div>
        <Camera 
          {...webcam} 
          isTesting={isTesting}
          recordFinished={() => {}} 
          videoUrl={''}
          btnText='Pronto'
          btnAction={() => {
            setIsTesting(false);
          }}
        />
      </div>
    ) : (
      <div>
        <Camera 
          {...webcam} 
          isTesting={isTesting}
          recordFinished={setVideo} 
          videoUrl={video?.url || ''}
          maxSeconds={300}
          actions={{
            onPreview: {
              primary: {
                text: 'Salvar Vídeo',
                action: saveVideo
              }
            }
          }}
        />
      </div>
    )}
    </PageContainer>
  )
}
