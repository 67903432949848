import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = '/city';

export default {
    async getCity(id: string | number){
        try {
            const {data} = await api.get(endpoint+'/'+id);
            return {data, isError: false};
        } catch (err: any) {
            return {
                data: err?.response?.data ?? {message: 'Falha ao processar requisição'},
                isError: true
            };
        }
    },

}