/* eslint-disable import/no-anonymous-default-export */
import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = '/user';

export default {
    all: async (params?: QueryParams) => getAll<any>({endpoint, params}),
    listAll: async () => listAll<any>({endpoint}),
    one: async (id: string, relations?: string) => getOne<any>({endpoint, id, relations}),
    destroy: async (id: string) => deleteOne({endpoint, id}),
    create: async (formData: Partial<any>) => create<any>({endpoint, formData}),
    update: async (id: string, formData: Partial<any>) => update<any>({ endpoint, formData, id }),
    getVideoUrl(path: string) {
      let url = process.env.REACT_APP_API;
  
      if (!url?.endsWith('/')) {
        url = url + '/';
      }
  
      return url + `user/get-video?path=${path}`
    },

    savePitchVideo(data: { video: Blob; }) {
      const formData = new FormData();
  
      formData.append('video', data.video);
  
      return api.post(`user/pitch/video`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })
    },
    async getPercentPerfil() {
        try {
            const { data } = await api.get(`${endpoint}/percent-perfil`);
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    }
}