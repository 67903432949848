import CardNews from "../CardNews";
import {useEffect, useState} from "react";
import newsApi from "../../../services/newsApi";
import {useNavigate} from "react-router-dom";
export default function RecentNews() {
    const navigate = useNavigate();
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    async function getData() {
        const {data, isError} = await newsApi.getNewsScreen();
        if (!isError){
            setNews(data)
        }
        setLoading(false)
    }

    useEffect(() => {getData()}, [])

    return(
        <div className={'bg-white w-full rounded-lg'}>
            <div className={'w-full border-b p-3'}>
                <div className={'text-[14px] text-[#263465] font-semibold'}>Notícias recentes</div>
            </div>
            {
                loading ?
                <span className="flex items-center justify-center h-20 p-5">
                  <svg className="animate-spin -ml-1 mr-3 h-[30px] w-[30px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </span>
                    :
                    <div>
                        {
                            news.length ?
                            news.map((noticia: any) => {
                                return <CardNews news={noticia}/>;
                            })
                                :
                                ''
                        }

                    </div>
            }

            <div onClick={() => navigate('/noticias')} className={'w-full border-t p-4 flex justify-center hover:cursor-pointer hover:opacity-75 font-semibold text-[14px] text-[#2C2C36]'}>
                VER TODAS AS NOTÍCIAS
            </div>
        </div>
    )
}