import PageContainer from "../../../components/container/PageContainer";
import Input from "../../../components/inputs/Input";
import Form from "../../../components/form/Form";
import {useEffect, useState} from "react";
import Button from "../../../components/button/Button";
import Storage from "../../../helpers/Storage";
import userApi from "../../../services/userApi";
import Alert from "../../../helpers/Alert";
import ReactPlayer from 'react-player';

export default function MeuVideo() {
    const userData: any = Storage.getUserData();
    const [state, setState] = useState({
        id: userData.usuario.id,
        url_video: '',
        video_pitch: '',
    });
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        const {data, isError} = await userApi.one(userData.usuario.id);
        if (!isError){
            setState({
                ...state, 
                'url_video': data.data.url_video,
                video_pitch: data.data.video_pitch
            })
        }
        setLoading(false);
    }

    useEffect(()=>{getData()}, [])


    async function onSubmitForm() {
        const {data, isError} = await userApi.update(state.id, state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        Alert.success('Salvo com sucesso');
    }

    return(
        <PageContainer>
            <div className={'text-[30px] text-semibold'}>Meu vídeo</div>
            {
                loading ?
                    <span className="flex items-center justify-center h-7">
                        <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </span>
                    :
                    <div>
                        <div className="mb-16 mt-10">
                            <h4 className="font-bold text-lg mb-2">Orientações</h4>
                            <div className="max-w-[540px]">
                                <video controls src={userApi.getVideoUrl('user/orientacao.mp4')}></video>
                            </div>
                        </div>

                        <div className="mb-16">
                            <h4 className="font-bold text-lg flex align-center">
                                <span className="mt-2 ">Meu Vídeo Pitch</span>
                                <Button 
                                    className='bg-primary h-[40px] text-white font-medium ml-3' 
                                    onClick={() => window.location.href = 'meu-video/pitch/gravar'}
                                >Gravar novo vídeo</Button>
                            </h4>
                            {state.video_pitch && <div className="max-w-[540px] mt-6">
                                <video controls src={userApi.getVideoUrl(state.video_pitch)}></video>
                            </div>}
                        </div>

                        <Form   onSubmit={onSubmitForm}>
                            <div>
                                <Input
                                    label={'Url do vídeo no Youtube'}
                                    placeholder={'Url do vídeo no Youtube'}
                                    value={state.url_video}
                                    setValue={v => setState({...state, 'url_video': v})}
                                />
                            </div>
                            <div className={'mt-3'}>
                                {
                                    state.url_video ?
                                        <ReactPlayer
                                            url={state.url_video}
                                            controls={true}
                                        />
                                        :
                                        ''
                                }
                            </div>
                            <div className={'mt-3'}>
                                <Button type={'submit'} className={'border w-[150px] bg-primary text-[#f7f9fb]'}>Salvar</Button>
                            </div>
                        </Form>
                    </div>
            }

        </PageContainer>
    )
}