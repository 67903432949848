import PageContainer from "../../../components/container/PageContainer";
import {useEffect, useState} from "react";
import newsApi from "../../../services/newsApi";
import CardNews from "../../../components/cards/CardNews";

export default function News() {
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    async function getData(){
        const {data, isError} = await newsApi.getNews(page);
        setList(data.data);
        setLoading(false)
    }

    useEffect(() => {getData()}, [])

    return(
        <PageContainer>
            <div className={'bg-white h-full'}>
            {
                loading ?
                    <span className="flex items-center justify-center h-20 p-5">
                      <svg className="animate-spin -ml-1 mr-3 h-[30px] w-[30px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    :
                    <div className={'flex flex-wrap gap-5 w-full'}>
                        {
                            list.map((li: any) => {
                                return <CardNews news={li}/>;
                            })
                        }
                    </div>
            }
            </div>

        </PageContainer>
    );
}