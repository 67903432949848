import Swal from 'sweetalert2';

interface BaseAlertParams {
  title?: string;
  message?: string;
  icon?: string;
  allowEnterKey?: boolean,
  allowEscapeKey?: boolean,
  allowOutsideClick?: boolean,
  showConfirmButton?: boolean,
  denyButtonText?: string;
  confirmButtonText?: string,
}

export interface AlertParams {
  buttonText?: string;
  denyButtonText?: string;
}

const Alert = {
  close: () => {
    Swal.close();
  },

  fire: (params: BaseAlertParams) => {
    return Swal.fire({
      showCloseButton: false,
      focusConfirm: false,
      focusCancel: false,
      focusDeny: false,
      returnFocus: false,
      buttonsStyling: false,
      confirmButtonText: params.confirmButtonText || 'Ok, entendi!',
      showConfirmButton: params.showConfirmButton !== false,
      denyButtonText: params.denyButtonText,
      showDenyButton: !!params.denyButtonText?.length,
      allowEnterKey: params.allowEnterKey !== false,
      allowEscapeKey: params.allowEscapeKey !== false,
      allowOutsideClick: params.allowOutsideClick !== false,
      background: '#1C1C24',
      color: '#B5B5BE',
      customClass: {
        title: 'h-[100px] !flex !p-0 justify-center items-center',
        htmlContainer: 'bg-[#191921]  h-[100px] !flex !p-0 items-center ',
        confirmButton: 'bg-system-primary w-[200px]',
      }
    });
  },

  await: (message?: string, title = 'Aguarde...') => {
    Swal.fire({
      title,
      text: message,
      showCloseButton: false,
      focusConfirm: false,
      focusCancel: false,
      focusDeny: false,
      returnFocus: false,
      buttonsStyling: false,
      showConfirmButton: false,
      showDenyButton: false,
      background: '#1C1C24',
      color: '#B5B5BE',
      customClass: {
        title: 'h-[100px] !flex !p-0 justify-center items-center',
        htmlContainer: 'bg-[#191921]  h-[100px] !flex !p-0 items-center ',
        confirmButton: 'bg-system-primary w-[200px]',
      }
    });
  },

  success: (message?: string, title = 'Sucesso!') => {
    // @ts-ignore
    return Swal.fire({
      title,
      text: message,
      background: '#1C1C24',
      color: '#B5B5BE',
      customClass: {
        title: 'h-[100px] !flex !p-0 justify-center items-center',
        htmlContainer: 'bg-[#191921]  h-[100px] !flex !p-0 items-center ',
        confirmButton: 'bg-system-primary w-[200px]',
      }
    });
  },

  error: (message?: string, title = 'Oops..', options?: AlertParams) => {
    Swal.fire({
      title,
      text: message,
      background: '#1C1C24',
      color: '#B5B5BE',
      customClass: {
        title: 'h-[100px] !flex !p-0 justify-center items-center',
        htmlContainer: 'bg-[#191921]  h-[100px] !flex !p-0 items-center ',
        confirmButton: 'bg-system-primary w-[200px]',
      }
    });
  },

  confirm: async (message?: string, title = 'Atenção!', options?: AlertParams) => {
    const response = await Swal.fire({
      title,
      text: message,
      showDenyButton: true,
      showCancelButton: false,
      denyButtonText: 'Voltar',
      confirmButtonText: 'Confirmar',
      background: '#1C1C24',
      color: '#B5B5BE',
      reverseButtons: true,
      customClass: {
        title: 'h-[100px] !flex !p-0 justify-center items-center',
        htmlContainer: 'bg-[#191921]  h-[100px] !flex !p-0 items-center ',
        confirmButton: 'bg-system-primary w-[200px]',
        denyButton: 'bg-[#191921] border border-border w-[200px]'
      }
    });

    return response.isConfirmed;
  },};

export default Alert;
