import { useLayoutEffect, useState } from "react"

export default function useWebcam(props: {
  videoTagId: string
}) {

  const [recording, setRecording] = useState(false);

  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([]);

  const [selectedMicrophone, setSelectedMicrophone] = useState<MediaDeviceInfo>();
  const [selectedCamera, setSelectedCamera] = useState<MediaDeviceInfo>();

  const [vad, setVad] = useState<any>();

  async function askForPermission() {
    if ((window as any).streamList?.length) {
      (window as any).streamList?.forEach((stream: any) => stream.getTracks().forEach((track: any) => track.stop()))
    }

    (window as any).streamList = [];
    try {
      (window as any).stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      (window as any).streamList.push((window as any).stream);
    } catch (err) {
      console.error(err);
    }
  }

  async function loadMicrophones() {
    const devices = (await navigator.mediaDevices.enumerateDevices()).filter(device => device.kind === 'audioinput')
    setMicrophones(devices);
    if (!devices.length) return;
    setSelectedMicrophone(devices.find(device => device.deviceId === 'default') || devices[0]);
  }

  async function loadCameras() {
    const devices = (await navigator.mediaDevices.enumerateDevices()).filter(device => device.kind === 'videoinput');
    setCameras(devices);
    setSelectedCamera(devices.find(device => device.deviceId === 'default') || devices[0]);
  }

  async function startRecording() {
    if (!(window as any).stream) return;
    (window as any).recorder = new MediaRecorder((window as any).stream, { mimeType: 'video/mp4' });

    setRecording(true);
    (window as any).recorder.start(1000);
    (window as any).blobs = [];

    (window as any).recorder.addEventListener('dataavailable', function(e: any) {
      (window as any).blobs.push(e.data);
    });

    (window as any).recorder.addEventListener('stop', function(e: any) {
      setRecording(false);
    });
  }

  async function stopRecording() {
    (window as any).recorder.stop();
    await new Promise(resolve => setTimeout(resolve, 1000)); // Record for 3 seconds
    const blob = new Blob((window as any).blobs, { type: (window as any).recorder.mimeType });
    const url = URL.createObjectURL(new Blob((window as any).blobs, { type: (window as any).recorder.mimeType }));
    return {
      blob,
      blobs: (window as any).blobs,
      url
    };
  }

  async function init() {
    if ((window as any).stream) return;
    await askForPermission();
    if (!(window as any).stream) return;
    await Promise.all([loadCameras(), loadMicrophones()]);
    // await startWebcam();
    await handleVAD();
  }

  async function handleVAD() {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource((window as any).stream);
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    scriptProcessor.onaudioprocess = function() {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      setVad(Math.round(average));
      // colorPids(average);
    };
  }
  

  useLayoutEffect(() => {
    console.clear()
    init();


    return () => {
      console.log('unmount', (window as any).streamList);
      (window as any).streamList?.forEach((stream: any) => stream.getTracks().forEach((track: any) => track.stop()))
    }
  }, []);

  return {
    cameras,
    microphones,
    selectedMicrophone,
    selectedCamera,
    setSelectedMicrophone,
    setSelectedCamera,
    recording,
    startRecording,
    stopRecording,
    vad,
  }
}