import Button from "../button/Button";
import { IoAddCircleOutline } from "react-icons/io5";
import Modal from "../modal/Modal";
import useModal from "../../hooks/useModal";
import Input from "../inputs/Input";
import {useState} from "react";
import Str from "../../helpers/Str";
import {FaRegTrashAlt} from "react-icons/fa";
import Alert from "../../helpers/Alert";
import academicBackgroundApi from "../../services/academicBackgroundApi";

export interface IAcademicBackground {
    data: any;
    setData: any;
}

export default function AcademicBackground({data, setData}: IAcademicBackground){
    const initialState = {
        id:'',
        course: '',
        academic_background: '',
        institution: '',
        location: '',
        currently_studying: true,
        initial_date: '',
        final_date: '',
        description: '',
    }
    const { modalProps, open, close } = useModal();
    const [state, setState] = useState(initialState);
    const [listCourses, setListCourses]: any = useState(data.academic_background ? data.academic_background : []);
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };

    const onSubmit = () => {
        const newlistCourses = [...listCourses]
        newlistCourses.push(state);
        setListCourses(newlistCourses)
        const newData = {...data};
        newData.academic_background = newlistCourses;
        setData(newData)
        setState(initialState);
        close();
    }

    const OnDelete = async (id: number) => {
        const confirm =  await Alert.confirm("Realmente deseja remover essa informação?", `Remover formação`);
        if (!confirm) return;
        const {data, isError} = await academicBackgroundApi.destroy(id.toString());
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        const newlistCourses = [...listCourses]
        const newL = newlistCourses.filter(l => l.id !== id);
        setListCourses(newL)
        Alert.success('Deletado com sucesso');

    }
    return(
        <>
            <div className={'w-full'}>
                <div className={'flex flex-col'}>
                    <div className={'flex justify-end'}>
                        <Button onClick={() => open()} className={'flex gap-2 border border-[#1C1C24] bg-[#2C2C36] text-[#FFF]'}>
                            <IoAddCircleOutline size={20} /> Adicionar nova
                        </Button>
                    </div>
                    <div className={'grid md:grid-cols-2 xs:grid-cols-1 mt-5 gap-2'}>
                        {
                            listCourses.length ?
                            listCourses.map((list: any) => {
                                return(
                                    <div className={'border flex  border-[#b3b3b7] rounded-md p-4 '}>
                                        <div className={'w-full flex flex-col col-span-1 gap-2'}>
                                            <div className={'text-[18px] font-semibold'}>
                                                Curso: {list.course}
                                            </div>
                                            <div className={'text-[14px]'}>
                                                Formação: {list.academic_background}
                                            </div>
                                            <div className={'text-[14px] text-[#BEBEBE]'}>
                                                {
                                                    list.currently_studying ?
                                                    'Início: '+Str.monthAndYearWritten(list.initial_date)
                                                    :
                                                     'De: '+Str.monthAndYearWritten(list.initial_date)+' até '+Str.monthAndYearWritten(list.final_date)
                                                }
                                            </div>
                                            <div className={'text-[14px]'}>
                                                Descrição: {list.description}
                                            </div>
                                        </div>
                                        <div className={'relative'}>
                                            <button type={'button'} onClick={() => OnDelete(list.id)} className={'absolute top-0 right-0 hover:opacity-75'}>
                                                <FaRegTrashAlt />
                                            </button>
                                        </div>
                                    </div>
                                )
                            }) : ''
                        }
                    </div>
                </div>

                <Modal
                    size={'mdm'}
                    title={"Adicionar formação acadêmica "}
                    {...modalProps}
                >

                    <div>
                        <div className={'grid grid-cols-12 gap-3'}>
                            <Input
                                className={'col-span-12'}
                                label={'Curso'}
                                name={'course'}
                                required
                                value={state.course}
                                setValue={v => handleChange(v, 'course')}
                            />
                            <Input
                                className={'col-span-12'}
                                label={'Formação'}
                                name={'academic_background'}
                                required
                                value={state.academic_background}
                                setValue={v => handleChange(v, 'academic_background')}
                            />
                            <Input
                                className={'col-span-12'}
                                label={'Instituição'}
                                name={'institution'}
                                required
                                value={state.institution}
                                setValue={v => handleChange(v, 'institution')}
                            />
                            <Input
                                className={'col-span-12'}
                                label={'Local'}
                                name={'location'}
                                required
                                value={state.location}
                                setValue={v => handleChange(v, 'location')}
                            />
                            <div className={'flex col-span-12 gap-2'}>
                                <input
                                    className="rounded-lg w-6 h-6 hover:cursor-pointer"
                                    id="custom-checkbox"
                                    name="currently_studying"
                                    type="checkbox"
                                    value="custom-checkbox"
                                    checked={state.currently_studying}
                                    onClick={(v) => handleChange(!state.currently_studying, 'currently_studying')}
                                />
                                <label className={'hover:cursor-pointer'} htmlFor="custom-checkbox">Cursando atualmente?</label>
                            </div>
                            <Input
                                className={'md:col-span-3 xs:col-span-12'}
                                label={'Data de início'}
                                name={'initial_date'}
                                type={'month'}
                                required
                                value={state.initial_date}
                                setValue={v => handleChange(v, 'initial_date')}
                            />
                            {
                                !state.currently_studying ?
                                    <Input
                                        className={'md:col-span-3 xs:col-span-12'}
                                        label={'Data de fim'}
                                        name={'final_date'}
                                        type={'month'}
                                        required
                                        value={state.final_date}
                                        setValue={v => handleChange(v, 'final_date')}
                                    />
                                    : ''
                            }
                            <Input
                                className={'col-span-12'}
                                label={'Descrição'}
                                name={'description'}
                                required
                                value={state.description}
                                setValue={v => handleChange(v, 'description')}
                            />
                        </div>
                        <div className={'mt-5 flex justify-end'}>
                            <Button
                                onClick={() => onSubmit()}
                                className={'flex w-[200px] gap-2 border border-[#1C1C24] bg-[#2C2C36] text-[#FFF]'}
                                type={'button'}
                            >
                                Salvar
                            </Button>
                        </div>
                    </div>

                </Modal>


            </div>
        </>
    )
}