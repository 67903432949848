import { QueryParams } from '../types';
import {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = '/certifications';

export default {
    all: async (params?: QueryParams) => getAll<any>({endpoint, params}),
    listAll: async () => listAll<any>({endpoint}),
    one: async (id: string, relations?: string) => getOne<any>({endpoint, id, relations}),
    destroy: async (id: string) => deleteOne({endpoint, id}),
    create: async (formData: Partial<any>) => create<any>({endpoint, formData}),
    update: async (id: string, formData: Partial<any>) => update<any>({ endpoint, formData, id }),

}