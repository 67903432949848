import { GoHome } from "react-icons/go";
import { FaRegFileVideo } from "react-icons/fa6";
import { TbReportAnalytics } from "react-icons/tb";
import { FaPeopleRoof } from "react-icons/fa6";
import { IoTrailSignOutline } from "react-icons/io5";
import { MdManageSearch } from "react-icons/md";
import { MdOutlineListAlt } from "react-icons/md";
import { LuListChecks, LuListTree } from "react-icons/lu";
import { FaRegAddressCard } from "react-icons/fa";
import { BiNetworkChart } from "react-icons/bi";
import { BsTrophy } from "react-icons/bs";
import Storage from "../helpers/Storage";
import { MdOutlineVideoCameraFront } from "react-icons/md";
import { MdOutlineEvent } from "react-icons/md";
export interface Menu {
    title: string;
    path?: string;
    icon?: any;
    subMenus?: Menu[];
    url?: string;
    comunidade?: boolean;
}
const userToken: any = Storage.getApiToken();

// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        title: 'Home',
        path: '/home',
        icon: GoHome,
    },
    {
        title: 'Vídeo pitch',
        path: '/meu-video',
        icon: FaRegFileVideo,
    },
    {
        title: 'Meus relatórios',
        path: '/relatorio',
        icon: TbReportAnalytics,
        url: process.env.REACT_APP_API+'/relatorios/'+userToken
    },
    {
        title: 'Comunidade',
        path: '/relatorio',
        icon: FaPeopleRoof,
        url: 'https://nichoos.top/integration/sso',
        comunidade: true

    },
    {
        title: 'Entrevista Simulada',
        path: '/entrevista-simulada',
        icon: MdOutlineVideoCameraFront,
    },
    {
        title: 'Trilhas de conteúdo',
        path: '/relatorio',
        icon: IoTrailSignOutline,
        url: "#"
    },
    {
        title: 'Gestão de carreira',
        path: '/relatorio',
        icon: MdManageSearch,
        url: "#"
    },
    {
        title: 'Minhas vagas',
        path: '/relatorio',
        icon: MdOutlineListAlt,
        url: "#"
    },
    {
        title: 'Meus certificados',
        path: '/relatorio',
        icon: LuListChecks,
        url: "#"
    },
    {
        title: 'Meus testes',
        path: '/relatorio',
        icon: LuListTree,
        url: "#"
    },
    {
        title: 'Meu cartão virtual',
        path: '/relatorio',
        icon: FaRegAddressCard,
        url: "#"
    },
    {
        title: 'Networking',
        path: '/relatorio',
        icon: BiNetworkChart,
        url: "#"
    },
    {
        title: 'Área de cursos',
        path: '/relatorio',
        icon: BsTrophy,
        url: "#"
    },
    {
        title: 'Agenda de eventos',
        path: '/relatorio',
        icon: MdOutlineEvent,
        url: "#"
    },

];