import noImage from "../../../assets/img/noImage.png";
import Storage from "../../../helpers/Storage";
import {User} from "../../../types";
import {useNavigate} from "react-router-dom";
export interface ICardUser {
    userData: User,
    percent: number,
    loading?: boolean,
}
export default function CardUser({userData, percent, loading}:ICardUser) {
    const navigate = useNavigate();
    return(
        <div className={'flex bg-white w-full p-5 border border-white rounded-lg gap-2'}>
            <img className={'w-[110px] h-[110px] rounded-full border'} src={noImage}/>
            <div className={'flex flex-col gap-3 text-[#263465]'}>
                <div className={'font-bold text-[18px]'}>
                    {userData.nome}
                </div>
                <div className={'text-[13px] font-semibold'}>
                    Perfil disc: {userData.perfil_disc}
                    <p/>
                    Perfil de Liderança: {userData.perfil_lideranca}
                </div>
                {
                    loading ?
                        <span className="flex items-center justify-center h-20 p-5">
                          <svg className="animate-spin -ml-1 mr-3 h-[15px] w-[15px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </span>
                        :
                        <div className={'flex flex-col w-full hover:cursor-pointer'}  onClick={() => navigate('/meu-perfil')}>
                            <div className={'text-[15px] font-semibold'}>Complete seu perfil</div>
                            <div className={'flex items-center gap-1'}>
                                <div className={`h-[8px] w-[100px] w-full bg-[#EDEFF8]  rounded-lg`}>
                                    <div className={`h-[8px] rounded-lg `} style={{width: percent+'%', backgroundColor:'#71BD00'}}/>
                                </div>
                                {percent+'%'}
                            </div>
                        </div>
                }

            </div>
        </div>
    )
}