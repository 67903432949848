import PageContainer from "../../../components/container/PageContainer";
import Form from "../../../components/form/Form";
import Input from "../../../components/inputs/Input";
import {useEffect, useState} from "react";
import ViaCepService from "../../../services/viaCepApi";
import statesApi from "../../../services/statesApi";
import educationLevelsApi from "../../../services/educationLevelsApi";
import professionalChallengesApi from "../../../services/professionalChallengesApi";
import InputSelect from "../../../components/inputSelect";
import { Accordion, AccordionItem  } from "@szhsin/react-accordion";
import cityApi from "../../../services/cityApi";
import {Textarea} from "../../../components/inputs/Textarea";
import AcademicBackground from "../../../components/academicBackground";
import ProfessionalExperience from "../../../components/professionalExperience";
import CourseOrCertification from "../../../components/courseOrCertification";
import Storage from "../../../helpers/Storage";
import userApi from "../../../services/userApi";
import InputImage from "../../../components/InputImage";
import Button from "../../../components/button/Button";
import Alert from "../../../helpers/Alert";
export default function MyProfile() {
    const [state, setState]: any = useState({
        id: '',
        image: '',
        name: '',
        email: '',
        birth_date: '',
        phone: '',
        cpf: '',
        sexo: 'm',
        educational_background: '',
        educational_level_id: '',
        cep: '',
        address: '',
        complement: '',
        neighborhood: '',
        city_id: '',
        state_id: '',
        bio: '',
        acting_area: '',
        current_company: '',
        interests: '',
        skills: '',
        linkedin: '',
        instagram: '',
        facebook: '',
        twitterX: '',
        languages: '',
        professional_goal: '',
        academic_background: [],
        certifications: [],
        professional_experience: [],
    });
    const [loading, setLoading] = useState(true);
    const [ibgeCity, setIbgeCity] = useState();
    const [loadingDataCity, setLoadingDataCity] = useState(false);
    const [loadingDataState, setLoadingDataState] = useState(false);
    const [stateOptionsState, setOptionsState]: any = useState();
    const [cityOptions, setCityOptions]: any = useState();

    const [educationLevelsOptions, setEducationLevelsOptions]: any = useState([]);

    const [professionalChallengesOptions, setProfessionalChallengesOptions]: any = useState([]);

    const cepSrv = new ViaCepService();
    const userData: any = Storage.getUserData();
    const getData = async () => {
        const [user, statesData, educationLevelsData, professionalChallengesData] = await Promise.all([
            userApi.one(userData.usuario.id),
            statesApi.listAll(),
            educationLevelsApi.listAll(),
            professionalChallengesApi.listAll()
        ]);
        if (!statesData.isError){
            const newState = user.data.data;
            newState.image = newState.image_path;
            setState(newState)
            getCity(user.data.data.state_id)
        }

        if (!statesData.isError){
            setOptionsState(statesData.data.map((dt:any) => {return {value: dt.id, label: dt.name, code: dt.code}}))
        }

        if (!educationLevelsData.isError){
            setEducationLevelsOptions(educationLevelsData.data.map((dt:any) => {return {value: dt.id, label: dt.name}}))
        }

        if (!professionalChallengesData.isError){
            setProfessionalChallengesOptions(professionalChallengesData.data.map((dt:any) => {return {value: dt.id, label: dt.name}}))
        }
        setLoading(false);
    }

    useEffect(() => {getData()},[])

    const getCity = async (idState: number, searchCep?: string) => {
        setLoadingDataCity(true)
        const {data, isError} = await cityApi.getCity(idState);
        if (!isError){
            const dataSelect = data.map((dt:any) => {return {value: dt.id, label: dt.name}});

            if (searchCep){
                const city = data.filter((ds: any) => ds.id === parseInt(searchCep));
                const newState = {...state}
                newState.city_id = city[0].id;
                setState(newState)
            }
            setCityOptions(dataSelect)
        }
        setLoadingDataCity(false)
    }

    useEffect(() => {
        if (state.state_id){
            getCity(parseInt(state.state_id), ibgeCity)
        }
    }, [ibgeCity])

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const searchCep = () => {
        if (state.cep.length === 8){
            setLoadingDataState(true)
            cepSrv.consultaCep(state.cep).then((cepResponse: any) =>{
                if (!cepResponse.data.erro){
                    const stateCep = stateOptionsState.filter((st: any) => st.code === cepResponse.data.uf);
                    const newState = {...state}
                    newState.state_id = stateCep[0].value
                    newState.neighborhood = cepResponse.data.bairro;
                    newState.address = cepResponse.data.logradouro;
                    setIbgeCity(cepResponse.data.ibge)
                    setState(newState)
                }
                setLoadingDataState(false)
            });
        }
    }

    const optionsSexo = [
        {value: 'm', label: 'Masculino'},
        {value: 'f', label: 'Feminino'},
    ]

    const onSelectState = (value: any) => {
        setState({...state, ['state_id']: value});
        getCity(value)
    }

    async function submit() {
        const {data, isError} = await userApi.update(state.id, state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        Alert.success('Editado com sucesso');
    }

    const onRemoveImage = () => {

        const newState = {...state};
        newState.image = '';
        setState(newState);
    }


    return(
        <PageContainer>
            {
                loading ?
                    <span className="flex items-center justify-center h-7">
                        <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </span>
                    :
                    <>
                        <div className={'text-[30px] text-semibold'}>Meu perfil</div>
                        <Form className={''} onSubmit={() => submit()}>

                            <Accordion >
                                <AccordionItem header={'Dados pessoais'}
                                               className="border-b"
                                               buttonProps={{
                                                   className: ({ isEnter }) =>
                                                       `flex w-full p-4  text-left hover:bg-slate-100 ${
                                                           isEnter ? "bg-slate-200" : 'bg-[#F1F5F9]'
                                                       }`
                                               }}
                                               contentProps={{
                                                   className: "transition-height duration-200 ease-out"
                                               }}
                                               panelProps={{ className: "p-4" }}
                                >
                                    <InputImage
                                        className={'jus flex gap-2 md:justify-start xs:justify-center'}
                                        urlPrevew={state.image ? state.image : '' }
                                        onAddImage={value => setState({...state, image: value})}
                                        onRemoveImage={() => onRemoveImage()}
                                    />
                                    <div className={'grid md:grid-cols-12 gap-3'}>
                                        <Input
                                            className={'md:col-span-2 xs:col-span-1'}
                                            label={'CPF'}
                                            name={'cpf'}
                                            value={state.cpf}
                                            mask={'CPF'}
                                            setValue={v => handleChange(v, 'cpf')}
                                        />
                                        <Input
                                            className={'md:col-span-4 xs:col-span-1'}
                                            label={'Nome'}
                                            name={'nome'}
                                            value={state.name}
                                            setValue={v => handleChange(v, 'name')}
                                        />
                                        <Input
                                            className={'md:col-span-4 xs:col-span-1'}
                                            label={'E-mail'}
                                            name={'email'}
                                            inputType={'email'}
                                            value={state.email}
                                            setValue={v => handleChange(v, 'email')}
                                        />
                                        <Input
                                            className={'md:col-span-2 xs:col-span-1'}
                                            label={'Telefone'}
                                            name={'phone'}
                                            mask={'cellphone'}
                                            value={state.phone}
                                            setValue={v => handleChange(v, 'phone')}
                                        />
                                    </div>
                                    <div className={'grid md:grid-cols-12 gap-3 mt-2'}>
                                        <Input
                                            className={'md:col-span-2 xs:col-span-1'}
                                            label={'Nascimento'}
                                            name={'nascimento'}
                                            type={'date'}
                                            value={state.birth_date}
                                            setValue={v => handleChange(v, 'birth_date')}
                                        />
                                        <InputSelect
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            selected={state.sexo}
                                            label={'Sexo'}
                                            className={'md:col-span-2 xs:col-span-1'}
                                            options={optionsSexo}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'sexo')}
                                            placeholder={'Sexo'}
                                        />
                                        <Input
                                            className={'md:col-span-2 xs:col-span-1'}
                                            label={'CEP'}
                                            mask={'CEP'}
                                            name={'cep'}
                                            value={state.cep}
                                            setValue={v => handleChange(v, 'cep')}
                                            onBlur={() => searchCep()}
                                        />
                                        <Input
                                            label={'Endereço'}
                                            value={state.address}
                                            className={'md:col-span-3 xs:col-span-1'}
                                            setValue={(v) => handleChange(v, 'address')}
                                        />
                                        <Input
                                            label={'Complemento'}
                                            value={state.complement}
                                            className={'md:col-span-3 xs:col-span-1'}
                                            setValue={(v) => handleChange(v, 'complement')}
                                        />
                                    </div>
                                    <div className={'grid md:grid-cols-12 gap-3 mt-2'}>
                                        <Input
                                            label={'Setor'}
                                            value={state.neighborhood}
                                            className={'md:col-span-3 xs:col-span-1'}
                                            setValue={(v) => handleChange(v, 'neighborhood')}
                                        />
                                        <InputSelect
                                            loading={loadingDataState}
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            selected={state.state_id}
                                            label={'Estado'}
                                            className={'md:col-span-3 xs:col-span-12'}
                                            options={stateOptionsState}
                                            onChangeSelect={(e: any) => onSelectState(e.value)}
                                            placeholder={'Estado'}
                                        />
                                        <InputSelect
                                            loading={loadingDataCity}
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            selected={state.city_id}
                                            label={'Cidade'}
                                            className={'md:col-span-3 xs:col-span-12'}
                                            options={cityOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'city_id')}
                                            placeholder={'Cidade'}
                                        />

                                    </div>
                                </AccordionItem>

                                <AccordionItem header="Dados Perfil"
                                               className="border-b"
                                               buttonProps={{
                                                   className: ({ isEnter }) =>
                                                       `flex w-full p-4 text-left hover:bg-slate-100 ${
                                                           isEnter ? "bg-slate-200" : 'bg-[#F1F5F9]'
                                                       }`
                                               }}
                                               contentProps={{
                                                   className: "transition-height duration-200 ease-out"
                                               }}
                                               panelProps={{ className: "p-4" }}
                                >
                                    <div className={'grid md:grid-cols-12 gap-3 mt-2'}>
                                        <Textarea
                                            rows={5}
                                            placeholder={'Escreva um pouco sobre você'}
                                            className={'md:col-span-6 xs:col-span-12'}
                                            label={'Bio'}
                                            value={state.bio}
                                            setValue={v => handleChange(v, 'bio')}
                                        />
                                        <Textarea
                                            rows={5}
                                            placeholder={'Idiomas'}
                                            className={'md:col-span-6 xs:col-span-12'}
                                            label={'Idiomas'}
                                            value={state.languages}
                                            setValue={v => handleChange(v, 'languages')}
                                        />
                                    </div>
                                    <div className={'grid md:grid-cols-12 gap-3 mt-2'}>
                                        <Textarea
                                            rows={5}
                                            placeholder={'Suas áreas de atuações'}
                                            className={'md:col-span-6 xs:col-span-12'}
                                            label={'Área de atuação'}
                                            value={state.acting_area}
                                            setValue={v => handleChange(v, 'acting_area')}
                                        />
                                        <Textarea
                                            rows={5}
                                            label={'Instituição/Empresas'}
                                            placeholder={'Instituições'}
                                            className={'md:col-span-6 xs:col-span-12'}
                                            value={state.current_company}
                                            setValue={v => handleChange(v, 'current_company')}
                                        />
                                    </div>
                                    <div className={'grid md:grid-cols-12 gap-3 mt-2'}>
                                        <Textarea
                                            rows={5}
                                            label={'Interesses'}
                                            placeholder={'Seus interesses'}
                                            className={'md:col-span-6 xs:col-span-12'}
                                            value={state.interests}
                                            setValue={v => handleChange(v, 'interests')}
                                        />
                                        <Textarea
                                            rows={5}
                                            label={'Habilidades'}
                                            placeholder={'Suas habilidades'}
                                            className={'md:col-span-6 xs:col-span-12'}
                                            value={state.skills}
                                            setValue={v => handleChange(v, 'skills')}
                                        />
                                    </div>
                                    <div className={'text-lg font-semibold'}>
                                        Redes Sociais
                                    </div>
                                    <div className={'grid grid-cols-12 gap-3 mt-2'}>
                                        <Input
                                            className={'md:col-span-6 xs:col-span-12'}
                                            placeholder={'@username'}
                                            label={'LinkdIn'}
                                            name={'linkedin'}
                                            value={state.linkedin}
                                            setValue={v => handleChange(v, 'linkedin')}
                                        />
                                        <Input
                                            className={'md:col-span-6 xs:col-span-12'}
                                            label={'Instagram'}
                                            placeholder={'@username'}
                                            name={'instagram'}
                                            value={state.instagram}
                                            setValue={v => handleChange(v, 'instagram')}
                                        />
                                    </div>
                                    <div className={'grid grid-cols-12 gap-3 mt-2'}>
                                        <Input
                                            className={'md:col-span-6 xs:col-span-12'}
                                            label={'Facebook'}
                                            placeholder={'@username'}
                                            name={'facebook'}
                                            value={state.facebook}
                                            setValue={v => handleChange(v, 'facebook')}
                                        />
                                        <Input
                                            className={'md:col-span-6 xs:col-span-12'}
                                            label={'X'}
                                            placeholder={'@username'}
                                            name={'twitterX'}
                                            value={state.twitterX}
                                            setValue={v => handleChange(v, 'twitterX')}
                                        />
                                    </div>
                                </AccordionItem>


                                <AccordionItem header="Formação acadêmica"
                                               className="border-b"
                                               buttonProps={{
                                                   className: ({ isEnter }) =>
                                                       `flex w-full p-4 text-left hover:bg-slate-100 ${
                                                           isEnter ? "bg-slate-200" : 'bg-[#F1F5F9]'
                                                       }`
                                               }}
                                               contentProps={{
                                                   className: "transition-height duration-200 ease-out"
                                               }}
                                               panelProps={{ className: "p-4" }}
                                >
                                    <div className={'grid md:grid-cols-12 mb-4'}>
                                        <InputSelect
                                            key={(Math.random() + 1).toString(36).substring(7)}
                                            selected={state.educational_level_id}
                                            label={'Escolaridade'}
                                            className={'md:col-span-6 xs:col-span-12'}
                                            options={educationLevelsOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'educational_level_id')}
                                            placeholder={'Escolaridade'}
                                        />
                                    </div>
                                    <AcademicBackground
                                        data={state}
                                        setData={setState}
                                    />
                                </AccordionItem>

                                <AccordionItem header="Experiências profissionais"
                                               className="border-b"
                                               buttonProps={{
                                                   className: ({ isEnter }) =>
                                                       `flex w-full p-4  text-left hover:bg-slate-100 ${
                                                           isEnter ? "bg-slate-200" : 'bg-[#F1F5F9]'
                                                       }`
                                               }}
                                               contentProps={{
                                                   className: "transition-height duration-200 ease-out"
                                               }}
                                               panelProps={{ className: "p-4" }}
                                >
                                    <div>
                                        <Textarea
                                            rows={5}
                                            placeholder={'Objetivo profissional'}
                                            className={'md:col-span-6 xs:col-span-12'}
                                            label={'Objetivo profissional'}
                                            value={state.professional_goal}
                                            setValue={v => handleChange(v, 'professional_goal')}
                                        />
                                    </div>
                                    <ProfessionalExperience
                                        data={state}
                                        setData={setState}
                                    />
                                </AccordionItem>
                                <AccordionItem header="Cursos e certificações"
                                               className="border-b"
                                               buttonProps={{
                                                   className: ({ isEnter }) =>
                                                       `flex w-full p-4  text-left hover:bg-slate-100 ${
                                                           isEnter ? "bg-slate-200" : 'bg-[#F1F5F9]'
                                                       }`
                                               }}
                                               contentProps={{
                                                   className: "transition-height duration-200 ease-out"
                                               }}
                                               panelProps={{ className: "p-4" }}
                                >
                                    <CourseOrCertification
                                        data={state}
                                        setData={setState}
                                    />
                                </AccordionItem>
                            </Accordion>
                            <div className={'mt-4 mb-5 pd-4'}>
                                <Button
                                    className={'flex w-[200px] gap-2 border border-[#1C1C24] bg-[#2C2C36] text-[#FFF]'}
                                    type={'submit'}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </Form>
                    </>

            }

        </PageContainer>
    )
}