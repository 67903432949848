import SideBar from "../SideBar";
import Navbar from "../NavBar";

export interface IPageContainer {
    children: React.ReactNode;
}

export default function PageContainer({children}: IPageContainer) {
    return(
        <div className={'md:h-screen md:overflow-hidden bg-[#F7F9FB] xs:pb-4'}>
            <Navbar/>
            <div className={'flex w-full md:h-full overflow-hidden'}>
                <SideBar/>
                <div className={'h-[calc(100vh_-_90px)] md:w-container xs:w-full  px-3 pt-5 max-h-full overflow-auto '}>
                    {children}
                </div>
            </div>
        </div>
    )
}