import { EntrevistaPergunta, EntrevistaResposta } from '../types/entrevista';
import api, { listAll, getOne } from './api';

const entrevistaApi =  {
  getVideoUrl(path: string) {
    let url = process.env.REACT_APP_API;

    if (!url?.endsWith('/')) {
      url = url + '/';
    }

    return url + `entrevistas/get-video?path=${path}`
  },

  getData() {
    return getOne<{qtde_obrigatorias: number}>({ endpoint: 'entrevistas', id: 'get-data' });
  },

  listarRespostasContagem(contagem: number) {
    return listAll<EntrevistaPergunta>({endpoint: 'entrevistas/listar-respostas/' + contagem});
  },

  listarRespostas() {
    return listAll<EntrevistaResposta>({endpoint: 'entrevistas/respostas'});
  },

  listarPerguntas(props?: { contagem_resposta?: number }) {
    const params: any = {};

    if (props?.contagem_resposta) {
      params['contagem_resposta'] = props.contagem_resposta;
    }

    const queryString = new URLSearchParams(params);
    const endpoint = 'entrevistas/perguntas?' + queryString.toString();

    return listAll<{
      perguntas: EntrevistaPergunta[];
      contagem_resposta: number;
    }>({endpoint});
  },

  saveAnswer(data: {
    resposta: Blob | string;
    perguntaId: number;
    contagem_resposta: number;
  }) {
    const formData = new FormData();

    formData.append('resposta', data.resposta);
    formData.append('pergunta_id', data.perguntaId.toString());
    formData.append('contagem_resposta', data.contagem_resposta.toString());

    return api.post(`entrevistas/respostas`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
  }
};

export default entrevistaApi;
