import React from 'react';
import { Navigate, Routes as ReactRouterDomRoutes, Route, RouteProps } from 'react-router-dom';
import appRoutes from './rotas';
import Storage from '../helpers/Storage';
export interface AppRoute {
    path: string;
    requiresAuth: boolean;
    element: JSX.Element;
    url?: string;
}

export function PrivateRoute({ children }: RouteProps) {
    const logged   = !!Storage.getApiToken();
    const userData = !!Storage.getUserData();

    return logged && userData ? (
        <>
            {children}
        </>
    ) : (
        <Navigate to="/" />
    );
}

export function Routes() {
    return (
        <ReactRouterDomRoutes>
            {appRoutes.map(({ path, requiresAuth, element , url}) =>
                requiresAuth ? (
                    <Route
                        key={path}
                        path={path}
                        element={<PrivateRoute>{element}</PrivateRoute>}
                    />
                ) : (
                    <Route
                        key={path}
                        path={path}
                        element={element}
                    />
                ))}
        </ReactRouterDomRoutes>
    );
}