import Str from '../../../helpers/Str';

export interface TextareaProps {
    className?: string;
    placeholder?: string;
    label?: string;
    error?: string;
    value?: string | number;
    setValue?(value: string): void;
    disabled?: boolean;
    hideLabel?: boolean;
    name?: string;
    key?: string;
    required?: boolean;
    onBlur?: any;
    legenda?: string;
    rows?: number;
}

export function Textarea({
     className,
     value,
     label,
     error,
     setValue,
     placeholder,
     hideLabel,
     name,
     key,
     required,
     onBlur,
     legenda,
     rows,
     ...rest
 }: TextareaProps) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleOnChange(event: any): void {
        setValue && setValue(event.target.value as string);
    }

    return (
        <label className={Str.tw('w-full ', className)}>
            {!hideLabel && <span className='font-medium'>{label}</span>}
            <textarea
                rows={rows}
                required={required}
                key={key}
                {...rest}
                name={name}
                placeholder={placeholder}
                value={value || ''}
                onChange={handleOnChange}
                onBlur={onBlur}
                className={Str.tw(
                    'py-2',
                    'bg-light border border-[#938888] bg-[#fff] rounded-md outline-none px-3 w-full',
                    ' focus:border-confianca transition'
                )}
            />
            {!!error?.length && <span className="text-red-600 text-sm">{error}</span>}
            {
                legenda ?
                    <div className={'text-sm text-gray-400'}>
                        {legenda}
                    </div> :''
            }

        </label>
    );
}