import React, { createContext, useState } from 'react';

import { User, AppContextType } from "../types";

export const AppContext = createContext({} as AppContextType);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AppProvider({ children }: any) {
    const [usuario, setUsuario] = useState<User>({} as User);
    return (
        <AppContext.Provider value={{
            usuario,
            setUsuario
        }}>
            {children}
        </AppContext.Provider>
    );
}