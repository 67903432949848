import PageContainer from "../../../components/container/PageContainer";
import Storage from "../../../helpers/Storage";
import noImage from '../../../assets/img/noImage.png';
import CardUser from "../../../components/cards/CardUser";
import RecentNews from "../../../components/cards/RecentNews";
import CardJobVacancies from "../../../components/cards/CardJobVacancies";
import CardAdvertisements from "../../../components/cards/CardAdvertisements";
import userApi from "../../../services/userApi";
import {useEffect, useState} from "react";
export default function HomeCliente() {
    const userData: any = Storage.getUserData();
    const [percent, setPercent] = useState(0);
    const [loading, setLoading] = useState(true);
    const getData = async () => {
        const {data, isError} = await userApi.getPercentPerfil();
        if (!isError){
            setPercent(data);
        }
        setLoading(false);
    }
    useEffect(() => {getData()}, [])
    return(
        <PageContainer>
            <div className={'w-full flex md:flex-row xs:flex-col xs:gap-4'}>
                <div className={'md:w-[65%] xs:w-full flex flex-col px-2 gap-5'}>
                    <div className={'md:text-[30px] xs:text-[20px] font-semibold'}>{userData.usuario.nome}, Seja bem vindo!</div>
                    <CardUser
                        loading={loading}
                        percent={percent}
                        userData={userData.usuario}
                    />

                </div>
                <div className={'flex flex-col md:w-[35%] xs:w-full h-full gap-3'}>
                    <RecentNews/>
                    <CardJobVacancies/>
                    <CardAdvertisements/>
                </div>
            </div>


        </PageContainer>
    )
}