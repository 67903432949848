import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function CardAdvertisements() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return(
        <div className={'w-full flex flex-col bg-white rounded-lg'}>
            <Carousel
                removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
                responsive={responsive}
                infinite={true}
                transitionDuration={6000}
                autoPlay
            >
                <div><img className={'rounded-lg'} src={'https://www.agenciatangelo.com.br/site2018/uploads/blog/20180504192255-importancia-do-material-de-apoio-para-o-departamento-comercial-agencia-tangelo.jpg'}/></div>
                <div><img className={'rounded-lg'} src={'https://solucionespackaging.com/wp-content/uploads/2018/04/coaching-comercial.jpg'}/></div>
            </Carousel>
        </div>
    )
}