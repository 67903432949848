import React, { useState } from 'react';
import Form from "../../../components/form/Form";
import {LoginData} from "../../../types";
import useForm from "../../../hooks/useForm";
import Input from "../../../components/inputs/Input";
import {Checkbox} from "../../../components/inputs/Checkbox";
import Button from "../../../components/button/Button";
import Storage from '../../../helpers/Storage';
import Alert from "../../../helpers/Alert";
import authApi from "../../../services/authApi";
import useAppContext from "../../../hooks/useAppContext";
import Logo from '../../../assets/img/logo-agroskills.png'
export default function Login() {
    const [loading, setLoading] = useState(false);
    const appContext = useAppContext();
    const { form, handleChange } = useForm<LoginData>({
        validator: (data) => data.password && data.email
    });
    async function handleSubmit() {
        setLoading(true);
        const { data, isError } = await authApi.login(form);
        if (isError) {
            setLoading(false);
            Alert.error(data.error);
            return;
        }

        Storage.setApiToken(data.data.token);
        Storage.setUserData({ usuario: data.data.usuario });

        appContext.setUsuario(data.data.usuario);
        setLoading(false);
        window.location.href = '/home';
    }
    return(
        <section
            className="h-screen  bg-no-repeat bg-cover bg-center bg-primary"
        >
            <div className="container h-full">
                <div className="flex items-center justify-center h-full " >
                    <div className={'flex flex-col gap-4'}>
                        <div className={'flex justify-center'}>
                            <img width={210} height={50} src={Logo}/>
                        </div>
                        <div className="border border-[#1C1C24] rounded-md bg-white  max-[450px]:w-[350px] pb-[30px] w-[469px] ">
                            <div className={"h-full p-4 "}>
                                <div className={'text-sm'}>
                                    Faça o <b>login</b> com as suas credenciais de acesso
                                </div>
                                <Form className="mt-5 grid gap-[22px]"  onSubmit={() => handleSubmit()}>
                                    <Input
                                        label={"E-mail"}
                                        large
                                        placeholder={'E-mail'}
                                        inputType="email"
                                        value={form?.email}
                                        setValue={(v) => handleChange(v, 'email')}
                                        required
                                    />
                                    <Input
                                        label={"Senha"}
                                        large
                                        placeholder={'Senha'}
                                        inputType="password"
                                        value={form?.password}
                                        setValue={(v) => handleChange(v, 'password')}
                                        required
                                    />
                                    {/*<div className={'mt-7 flex justify-between'}>
                                        <Checkbox
                                            className="col-span"
                                            label="Lembrar senha"
                                            setValue={(v) => handleChange(v, 'password')}
                                        />
                                        <div className={'text-[#fafafb] font-regular'}>
                                            Esqueceu a senha?
                                        </div>
                                    </div>*/}
                                    <Button loading={loading} type={'submit'} className={'col-span-1 bg-primary text-white font-bold'}>Login</Button>

                                </Form>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
}