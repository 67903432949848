import noImage from "../../assets/img/noImage.png";
import {ChangeEvent, Component, useEffect, useState} from "react";
import {MdDelete} from "react-icons/md";
import {RiImageAddLine} from "react-icons/ri";
interface IImageInputProps {
    className?: string;
    multiple?: boolean;
    addMoreType?: boolean;
    onAddImage?: (file: File) => void;
    onAddMultiple?: (file: File[]) => void;
    onRemoveImage?: () => void;
    profilePage?: boolean;
    previewFile?: File | '-1';
    disableAutoPreview?: boolean;
    bgImage?: string;
    urlPrevew?: string;
    imgSquare?: boolean;
    homeIcon?: boolean;
    width?: string;
    height?: string;
    id?: string;
}

class ImageAdd extends Component<{ color: string }> {
    render() {
        return null;
    }
}

export default function InputImage({
   className,
   addMoreType,
   onAddImage,
   multiple,
   onAddMultiple,
   previewFile,
   disableAutoPreview,
   onRemoveImage,
   bgImage,
   urlPrevew,
   imgSquare,
   homeIcon,
   width,
   height,
   id,
}: IImageInputProps){

    //const id = uuid();
    //const [preview, setPreview] = useState<string | ArrayBuffer | null>('');
    const [preview, setPreview] = useState<any>(urlPrevew);
    function convertFileToBase64(file: any, callback: any) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => callback(reader.result);
    }
    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const reader = new FileReader();
        if (event.target.files === null) return;
        const files = Array.from(event.target.files);
        //if (onAddImage) onAddImage(files[0]);
        if (onAddImage) {
            convertFileToBase64(files[0], (str: any) => onAddImage(str))
            onAddImage(files[0]);
        }
        if (onAddMultiple) onAddMultiple(files);
        if (disableAutoPreview) return;
        if (!addMoreType && files[0] !== undefined) {
            reader.onload = () => {
                if (reader.result) {
                    setPreview(reader.result);
                }
            };

            reader.readAsDataURL(files[0]);
        }
    }

    function handleRemoveImage() {
        setPreview('');
        if (onRemoveImage) onRemoveImage();
    }

    useEffect(() => {
        if (bgImage !== undefined) {
            setPreview(bgImage);
        }
        if (!addMoreType && previewFile && previewFile !== '-1') {
            const reader = new FileReader();

            reader.onload = () => {
                setPreview(reader.result);
            };

            reader.readAsDataURL(previewFile);
        }
    }, [previewFile, bgImage]);

    return(
        <div className={className}>

            <img
                className={`${imgSquare ? 'rounded-md' : 'rounded-full'} bg-[#d4d4d4]  border border-4 border-border ${width ? width : 'w-[100px]'} ${height ? height : 'h-[100px]'} `}
                src={preview ? preview : noImage}
            />
            <div className={'gap-2 flex flex-col justify-center'}>
                <div className="">
                    <label
                        htmlFor={id ? id : "upload"}
                        className="flex flex-col items-center gap-2 cursor-pointer"
                    >
                        <RiImageAddLine />
                    </label>
                    <input
                        multiple={true}
                        onChange={handleChange}
                        id={id ? id : "upload"}
                        type="file"
                        className="hidden"
                    />
                </div>
                <button type={'button'} onClick={handleRemoveImage}>
                    <MdDelete />
                </button>
            </div>
        </div>
    )
}