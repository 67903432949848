import React from 'react';
import Str from '../../../helpers/Str';

export interface ButtonProps {
  className?: string;
  children?: React.ReactNode;
  type?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  variant?: 'confianca' | 'constancia' | 'conhecimento';
}

export default function Button({
  className,
  children,
  type = 'button',
  loading,
  disabled,
  variant,
  ...rest
}: ButtonProps) {
  const finalDisabled = disabled || loading;

  return (
    <button {...rest} disabled={finalDisabled} type={type} className={Str.tw(
      'border-border rounded-md  p-3 py-2 hover:opacity-75',
      'transition',
      'disabled:opacity-70 disabled:pointer-events-none',
      'flex items-center justify-center',
      className,
      /*variant === 'confianca' && 'bg-confianca  text-white font-bold hover:bg-confianca-100',
      variant === 'constancia' && 'bg-constancia  text-white font-bold hover:bg-constancia-100',
      variant === 'conhecimento' && 'bg-conhecimento  text-white font-bold hover:bg-conhecimento-100',*/
    )}>
      {loading ? (
        <span className="flex items-center justify-center h-7">
          <svg className="animate-spin  h-[18px] w-[18px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </span>
      ) : (
        <>{children}</>
      )}
    </button>
  );
}
