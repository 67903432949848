import { useEffect, useState } from 'react';
import PageContainer from '../../../components/container/PageContainer';
import { EntrevistaPergunta } from '../../../types/entrevista';
import Alert from '../../../helpers/Alert';
import service from '../../../services/entrevistaApi';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';


export default function RespostasEntrevista() {
  const [perguntas, setPerguntas] = useState<EntrevistaPergunta[] | null>(null);
  const route = useParams();

  async function load() {
    Alert.await('Carregando perguntas', 'Aguarde...');
    const { data, isError } = await service.listarRespostasContagem(route.contagem as any);
    Alert.close();
    if (isError) {
      Alert.error(data.message || data.error || 'Falha ao carregar perguntas.');
      return;
    }
    setPerguntas(data);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <PageContainer>
      <div className={'text-[30px] mb-4'}>Respostas Entrevista</div>

      {perguntas?.map(pergunta => (
        <div key={pergunta.id} className={'mb-4 bg-white rounded shadow p-5 max-w-[680px]'}>
          <div className={'text-lg font-bold'}>{pergunta.enunciado}</div>
          {!pergunta.respostas?.length ? <div>Nenhuma resposta</div> : pergunta.tipo === 'Vídeo' 
            ? <ReactPlayer url={service.getVideoUrl(pergunta.respostas[0].resposta)} controls loading='lazy' /> 
            : <div>{pergunta.respostas[0].resposta}</div>}
        </div>
      ))}
    </PageContainer>
  )
}
