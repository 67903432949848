import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./cardJobVacancies.css"
import noImage from "../../../assets/img/noImage.png";
import vagasApi from "../../../services/vagasApi";
import {useEffect, useState} from "react";
export default function CardJobVacancies() {
    const [vagas, setVagas]: any = useState([]);
    const [loading, setLoading] = useState(true);
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const getData = async () => {
        const [vagasData] = await Promise.all([vagasApi.getVagas()]);
        if (!vagasData.isError){
            console.log(vagasData.data);
            setVagas(vagasData.data)
        }
        setLoading(false)
    }
    useEffect(() => {getData()},[])

    const Card = (data?: any) => {

        data = data.data;
        const remuneracao = (parseFloat(data.remuneracao)).toLocaleString('en-US', {
            style: 'currency',
            currency: 'BRL',
        })

        return(
            <div className={'w-full flex p-3 gap-2 hover:opacity-75 hover:cursor-pointer justify-center items-center '}>
                <img className={'w-[70px] h-[70px] border '} src={noImage}/>
                <div className={'flex flex-col'}>
                    <div className={'text-[14px] text-[#263465]'}>
                        <span className={'font-bold'}>Empresa:</span> {data.empresa}
                    </div>
                    <div className={'text-[14px] text-[#263465]'}>
                        <span className={'font-bold'}>Vaga:</span> {data.nome}
                    </div>
                    <div className={'text-[14px] text-[#263465]'}>
                        <span className={'font-bold'}>Local:</span> {data.city.name} - {data.state.name}{/*Goiânia-GO*/}
                    </div>
                    <div className={'text-[14px] text-[#263465]'}>
                        <span className={'font-bold'}>Salário:</span> {remuneracao}
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className={'w-full flex flex-col bg-white rounded-lg'}>
            <div className={'w-full border-b p-3'}>
                <div className={'text-[14px] text-[#263465] font-semibold'}>Vagas</div>
            </div>
            {
                loading ?
                    <span className="flex items-center justify-center h-20 p-5">
                      <svg className="animate-spin -ml-1 mr-3 h-[30px] w-[30px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    :
                    vagas.length ?

                        <Carousel
                            responsive={responsive}
                            infinite={vagas.length > 1}
                            autoPlay
                            transitionDuration={4000}
                        >
                            {
                                vagas.map((vaga: any) => {
                                    return <Card data={vaga}/>
                                })
                            }
                        </Carousel>
                        :
                        <div className={'flex items-center justify-center p-3 text-[#263465]'}>
                            Nenhuma vaga disponivel
                        </div>
            }

        </div>
    )
}